<template>
  <v-container fluid class="ma-0 pa-0 heritage-scroll flex-column align-center">
    <span>Les données affichées sur cette page ne sont pas fiables</span>
    <v-row class="py-12"></v-row>
    <div id="temps"></div>
    <v-row class="py-12"></v-row>
    <div id="repartition"></div>
  </v-container>
</template>

<script>
import Api from "@/api/kuberact";
import vegaEmbed from "vega-embed";
import { mapGetters } from "vuex";
export default {
  components: {
    //
  },
  data: () => ({
    loading: false,
    specTemps: {
      $schema: "https://vega.github.io/schema/vega-lite/v4.json",
      width: 600,
      height: 200,
      description: "A simple bar chart with embedded data.",
      data: {
        values: [],
      },
      encoding: {
        x: {
          field: "semaine",
          title: "Temps (semaine)",
          type: "ordinal",
          axis: {
            labelAlign: "center",
            labelAngle: "0",
          },
        },
      },
      layer: [
        {
          transform: [{ filter: "datum.temps !== 'Prévu'" }],
          mark: { point: true, opacity: 0.7, type: "area", tooltip: true },
          encoding: {
            y: {
              type: "quantitative",
              field: "valeur",
              title: "Quantité (JH)",
              stack: true,
              axis: {
                labelAlign: "right",
              },
            },
            color: {
              field: "temps",
              title: "Temps",
              type: "nominal",
              scale: { range: ["#B71C1C", "#134d87", "#aad408"] },
            },
          },
        },
        {
          transform: [{ filter: "datum.temps === 'Prévu'" }],
          mark: { point: true, type: "line", interpolate: "monotone" },
          encoding: {
            y: {
              type: "quantitative",
              field: "valeur",
            },
            color: {
              field: "temps",
              type: "nominal",
            },
          },
        },
      ],
    },
    specRepartiton: {
      $schema: "https://vega.github.io/schema/vega-lite/v4.json",
      width: 600,
      height: 200,
      description: "A simple donut chart with embedded data.",
      data: {
        values: [],
      },
      layer: [
        {
          mark: { type: "arc", innerRadius: 0, tooltip: true },

          encoding: {},
        },
        {
          mark: { type: "text", radius: 100, radiusOffset: 15 },
          encoding: {
            text: { field: "nombreJour", type: "quantitative" },
          },
        },
      ],
      encoding: {
        color: {
          field: "prenomNom",
          title: "Travailleur",
          type: "nominal",
          scale: { scheme: "greenblue" },
        },
        theta: {
          stack: true,
          field: "nombreJour",
          type: "quantitative",
          title: "Pointé (JH)",
        },
      },
      view: { stroke: null },
    },
    //
  }),
  computed: {
    type: function () {
      // 'tache' || 'projet'
      return this.$route.params.type;
    },
    id: function () {
      return this.$route.params.id;
    },
    ...mapGetters("entreprise", ["getPrenomNom"]),
  },
  watch: {
    //
  },
  created() {
    const fonctionRecuperationTemps =
      this.type === "projet" ? Api.getTempsParProjet : Api.getTempsParTache;
    const fonctionRecuperationRepartition =
      this.type === "projet"
        ? Api.getRepartitionParProjet
        : Api.getRepartitionParTache;

    fonctionRecuperationTemps(this.id)
      .then((response) => {
        this.specTemps.data.values = response.data.reduce((acc, semaine) => {
          const semaineFormatee = `${semaine.annee}-${(
            semaine.semaine + ""
          ).padStart(2, "0")}`;
          // Formater la données pour vega
          return acc.concat([
            {
              semaine: semaineFormatee,
              temps: "Prévu",
              valeur: semaine.tempsPrevu / 7,
            },
            {
              semaine: semaineFormatee,
              temps: "Total pointé",
              valeur: semaine.tempsPointe / 7,
            },
            {
              semaine: semaineFormatee,
              temps: "Reste à faire",
              valeur: semaine.tempsResteAFaire / 7,
            },
          ]);
        }, []);
        vegaEmbed("#temps", this.specTemps, {
          renderer: "svg",
          actions: {
            export: true,
            source: false,
            compiled: false,
            editor: false,
          },
          scaleFactor: 2,
          // https://github.com/vega/vega-themes
          // theme: "googlecharts",
        });
      })
      .catch((err) => console.log(err));

    fonctionRecuperationRepartition(this.id)
      .then((response) => {
        this.specRepartiton.data.values = response.data.map((ressource) =>
          Object.assign(ressource, {
            prenomNom: this.getPrenomNom(ressource.idRessource),
          })
        );
        vegaEmbed("#repartition", this.specRepartiton, {
          renderer: "svg",
          actions: {
            export: true,
            source: false,
            compiled: false,
            editor: false,
          },
          scaleFactor: 2,
          // https://github.com/vega/vega-themes
          // theme: "googlecharts",
        });
      })
      .catch((err) => console.log(err));
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
  methods: {
    //
  },
};
</script>

<style scoped>
</style>
